import React, { Suspense, lazy } from 'react';
import './css/styles.css';

const Home = lazy(() => import('./components/Home/Home'));
const Sidebar = lazy(() => import('./components/Sidebar/Sidebar'));
const FindUs = lazy(() => import('./components/FindUs/FindUs'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const RRSS = lazy(() => import('./components/RRSS/RRSS'));
const Menu = lazy(() => import('./components/Menu/Menu'));
const Promos = lazy(() => import('./components/Promos/Promos'));

const renderLoader = () => <p>Cargando London Fresh</p>; 

function App() {
  return (
    <Suspense fallback={renderLoader()}>
    <div className="container">
      <Sidebar />
      <Home/>
      <Menu />
      <FindUs />
      <Promos />
      <RRSS />
      <Footer />
      
    </div>
    </Suspense>
  );
}

export default App;
